import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "amfe-flexible";
import "./style/reset.css";
import "vant/lib/index.css";
import i18n from "./language/index.js";
import {
  Button,
  Toast,
  Checkbox,
  Field,
  Icon,
  DropdownMenu,
  DropdownItem,
} from "vant";

const app = createApp(App);

app.use(router);
app.use(i18n);
app
  .use(Button)
  .use(Toast)
  .use(Field)
  .use(Checkbox)
  .use(Icon)
  .use(DropdownMenu)
  .use(DropdownItem);
app.mount("#app");
