import { createI18n } from "vue-i18n"; //引入vue-i18n组件
import zh from "./zh";
import en from "./en";

//注册i18n实例并引入语言文件
const i18n = createI18n({
  legacy: false,
  locale: getQueryString("i18n") || localStorage.getItem("i18n") || "zh", // 语言标识（缓存里面没有就用中文）
  fallbackLocale: "zh", //没有英文的时候默认中文语言
  messages: {
    zh,
    en,
  },
});
function getQueryString(variable) {
  var index = window.location.href.indexOf("?"); //获取地址栏路径并进行分割
  var query = window.location.href.substr(
    index + 1,
    window.location.href.length
  );
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false; //如果没有参数就返回false 值，就不会出现上边报错的情况了
}
// console.log(getQueryString("i18n"));
export default i18n;
