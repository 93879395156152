import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "contrainer"
};
import { onBeforeMount, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import NavBar from "./components/v-nav-bar.vue";
import { useI18n } from "vue-i18n";
export default {
  __name: 'App',
  setup(__props) {
    const i18n = useI18n();
    const route = useRoute();
    const router = useRouter();
    const logout = () => {
      localStorage.clear();
      window.location.reload();
    };
    router.isReady().then(() => {
      // Do somethings.. For example to save to global state:
      console.log(route.query, route.query.i18n, "参数1》》》》》》");
      if (route.query.i18n) {
        i18n.locale.value == route.query.i18n;
        localStorage.setItem("i18n", route.query.i18n);
      }
    });
    // await router.isReady();

    onMounted(() => {
      console.log(route.query, route.query.i18n, "参数》》》》》》");
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(NavBar), _createVNode(_component_router_view)]);
    };
  }
};