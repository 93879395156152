import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
const routes = [
  {
    path: "/",
    component: () => import("../views/home.vue"),
  },
  {
    path: "/login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/test",
    component: () => import("../views/test.vue"),
  },
  {
    path: "/report",
    component: () => import("../views/report.vue"),
  },
  {
    path: "/result",
    component: () => import("../views/result.vue"),
  },
  {
    path: "/select-role",
    component: () => import("../views/select-role.vue"),
  },
  {
    path: "/history",
    component: () => import("../views/history.vue"),
  },
  {
    path: "/create-group",
    component: () => import("../views/create-group.vue"),
  },
  {
    path: "/report-group",
    component: () => import("../views/report-group.vue"),
  },
  {
    path: "/others/home",
    component: () => import("../views/others/home.vue"),
  },
  {
    path: "/others/test",
    component: () => import("../views/others/test.vue"),
  },
  {
    path: "/others/result",
    component: () => import("../views/others/result.vue"),
  },
  {
    path: "/others/select-role",
    component: () => import("../views/others/select-role.vue"),
  },
  {
    path: "/others/report-other",
    component: () => import("../views/others/report-other.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
  routes,
});

export default router;
