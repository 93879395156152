export default {
  textType: "en",
  text100001: "Start",
  text100002: "Build a team role portraits",
  text100003: "Records",
  text100004: "Time:",
  text100005: "Main Role",
  text100006: "Backup Role",
  text100007: "Personal Data",
  text100008: "Team Result",
  text100009: "Share",
  text100010: "Next",
  text100011: "Skip",
  text100012: "Start",
  text100013: "Previous",
  text100014: "Next",
  text100015: "Submit",
  text100016: "The assigned score did not reach 10 points",
  text10001611: "The current allocation score exceeds 10 points",
  text100017: "Report",
  text100018: "Overall data",
  text100019: "Based on your score assignment, the results are as follows:",
  text100020: "Main Role in Self-Evalutaion",
  text100021:
    "The role with the highest score is that you are most suitable to play in the team.",
  text100022: "Backup Role in Self-Evaluation",
  text100023:
    "You can play these roles when the team does not need your main role.",
  text100024: "Low-scoring roles in Self-Evalutaion",
  text100025:
    "The two lowest scores point out the possible weaknesses of the testee, but instead of having to change yourself in these areas, you should find a colleague who can offset your shortcomings in these areas.",
  text100026: "Other roles of Self-Evaluation",
  text100027:
    "Here are the instructions for your other roles for your reference.",
  text100028: 'View "The Belbin Team Role Circle"',
  text100029: "The Belbin Team Role Circle",
  text100030: "Back",
  text100031: "View question",
  text100032: "Self",
  text100033: "AVG.Observer",
  text100034: "Main",
  text100035: "Backup",
  text100036: "Invitation Record",
  text100037: "Invite Observer-Test",
  text100038: "Share",
  text100039: "Creating a team",
  text100040: "Select a result to create",
  text100041: "Time",
  text100042: "Main Role",
  text100043: "Backup Role",
  text100044: "Create",
  text100045: "Get a new result",
  text100046: "Back",
  text100047: "View question",
  text100048: "Overall data",
  text100049: "Based on your score assignment, the results are as follows:",
  text100050: "Members",
  text100051: "Ma",
  text100052: "Ba",
  text100053: "Invite others to join this team",
  text100054:
    "*The others needs to have evaluation permission before they can join",
  text100055: "TeamComple",
  text100056: "Self-Evaluation",
  text100057: "Observer Assessments",
  text100058:
    "This test is copyrighted by Belbin, and this test is only an automatic evaluation of its test method through the program",
  text100059:
    "To build effective teams, team members need complementary roles and tacit collaboration. There are eight key roles: Plant,Resource Investigator,Completer,Monitor Evaluator,Co-ordinator,Implementer,Teamworker and Shaper. Creating a high performance team requires each team member to have a clear understanding of their respective lead roles and responsibilities.",
  text100060:
    "This test can help self-awareness and identify perception gaps, and is widely used in team formation. The individual report can only help you analyze your typical style traits in a team from an individual perspective. The results will be more accurate if you invite 3-5 people who know you well to complete the Observer Assessments.",
  text100061: "Tips:",
  text100062: "1. There are no right or wrong answers;",
  text100063:
    "2. The evaluation focuses on your current situation in the team.",
  text100064: "The evaluation has a total of 7 sections",
  text100065:
    "Each section contains 10 questions. For each section you have 10 points to allocate to these 10 questions. Please allocate these 10 points wisely to the options that fit your situation. In extreme cases, the 10 points can be allocated to all options, or all to one option.",
  text100066:
    "Then after filling in the points to each option, you can either click directly to add or subtract, or you can fill in a value by keyboard.",
  text100067: "Go to buy",
  text100068: "Report",
  text100069: "Back",
  text100070: "Join",
  text100071: " a team",
  text100072: "and",
  text100073: "others",
  text100074: "anonymous",
  text100075: "Joining a team...",
  text100076: "Incomplete",
  text100077: "Completed",
  text100078: "You have an saved draft, do you want to use it?",
  text100079: "Discard",
  text100080: "Use",
  text100081: "Resource Investigator",
  text100082: "Teamworker",
  text100083: "Co-ordinator",
  text100084: "Shaper",
  text100085: "Completer Finisher",
  text100086: "Implementer",
  text100087: "Monitor Evaluator",
  text100088: "Plant",
  text100089: "Please save the QR code and share it with your team members",
  text100090: "Or share the link below with your team members",
  text100091: "You can invite others again in Result - Team Data",
  text100092: "Please save the QR code and share it with your friends",
  text100093: "Or share the link below with your friends",
  text100094: "Share my results",
  text100095: "person",
  text100096: "Successful copy",
  text100097: "Copy Failed",
  text100098: "Copy",
  text100099: "More in line with him/her",
  text100text100: "More in line with me",
  text100101: "Choose one option between the same score",
  text100102: "Please go to personal data to confirm the role first",
  text100103:
    "Creating a high performance team requires each team member to have a clear understanding of their respective lead roles and responsibilities.",
  text100104:
    "Test whether the leading role and his/her self-evaluation of the inviter are consistent, and help him/her understand his/her role cognition and feelings in the team.",
  text100105: "View result",
  text100106: "Start evaluating for him/her",
  text100107: "You can't do Observer Assessment by yourself",
  text100108: "Go to the team role homepage",
  text100109: "Based on your score assignment, the results are as follows:",
  text100110: "Main Role in his/her evaluation",
  text100111:
    "The role with the highest score is that he/she is most suitable to play in the team.",
  text100112: "Backup Role in his/her evaluation",
  text100113:
    "He/She can play these roles when the team does not need his/her main role.",
  text100114: "Low-scoring roles in his/her evaluation",
  text100115: "Other roles of his/her evaluation",
  text100116: "I also want to test",
  text100117:
    "The two lowest scores point out the possible weaknesses of the testee, but instead of having to change him/herself in these areas, he/she should find a colleague who can offset his/her shortcomings in these areas.",
  text100118: "Points for this section",
  text100119:
    "There are roles with the same score in your main role section, please refer to the description and choose the role that suits you better.",
  text100120:
    "There are roles with the same score in your backup role section, please refer to the description and choose the role that suits you better.",
  text100121: "Evaluate for",
  text100122: "what his/her role in the team.",
  text100123: "The evaluation has a total of 7 sections.",
  text100124: "Join the team",
  text100125: "Based on the team's scores,",
  text100126: "Team evaluation report",
  text100127: "In the 8 roles, they are as follows:",
  text100128: "Ta shared with you the results of Ta's team role test",
  text100129:
    "In the process of team work, team members often have different characteristics of cooperation. These characteristics are not good or bad in themselves, but if team leaders can understand the cooperation characteristics of everyone in advance, they can better match human resources and give full play to everyone's characteristics when considering the composition of project personnel and task allocation.",
  text100130: "Other evaluation record",
  text100131: "Based on your score assignment, ",
  text100132: "The Final Step",
  text100133:
    "There are roles with the same score in his/her main role section, please refer to the description and choose the role that suits his/her better.",
  text100134:
    "There are roles with the same score in his/her backup role section, please refer to the description and choose the role that suits his/her better.",
  text100135: "？",
  text100136: "Undecided",
  text100137: "I am the ",
  text100138: " in the team.What's your role?",
  text100139: "Join my team and find your role!",
  text100140:
    "What do you think my role is in the team? Please evaluate for me",
  text100141: "No permission, please contact the admin",
  text100142: "Successfully copied, send it to your friends",
  text100143: "Successfully copied, send invitation to your team members",
  text100144: "Note: the current allocation score",
  text100145: "10, please confirm and continue with the operation.",
  text100146: "is more than",
  text100147: " has not reached",
  text100148: "Submitted, do not submit again.",
  text100149: "Note: your no.",
  text100150:
    "question has not been completed, please continue after completion",
  text100151:
    "sorry, his other-evaluation submission quota is full.please do not submit again",
  text100152: "Join successfully",
  text100153: "Login is expired, please login again",
  text100154: "Choose a test",
  text100155: "You are already in the team, do not join again",
  text100156: "Create successfully",
  text100157: "",
  text100158: "",
  text100159: "",
  text100160: "",
  text100161: "",
  text100162: "",
  text100163: "",
  text100164: "",
  text100165: "",
  text100166: "",
  text100167: "",
  text100168: "",
  text100169: "",
  text100170: "",
  200057: "Personal",
  200006: "Enter your mobile phone number",
  200011: "Enter your password",
  200001: "Registration successful",
  200002: "Failed to send",
  200003: "Sent successfully",
  200004: "Resend",
  200005: "Repeat",
  200006: "Enter your mobile phone number",
  200007: "Fill in all of the information",
  200008: "The passwords entered do not match",
  200009: "Enter your password again",
  200010: "Enter at least 6 characters including numbers and letters",
  200011: "Enter your password",
  200012: "Enter your verification code",
  200013: "You have already registered. Please log in.",
  200015: "Your phone number",
  200017: "Send verification code",
  200018: "Enter your password again",
  200019: "Enter a password of 6-16 letters and numbers in length.",
  200020: "Personal registration",

  200230: "Save password",
  200039: "Forgot password",
  200056: "Log in",
  200227: "Select Language",
  200054: "Click here to register",
  200055: "No account",
  200197: "Expand",
  200194: "Login successful!",
};
