export default {
  textType: "cn",
  text100001: "开始测试",
  text100002: "建立团队角色画像",
  text100003: "历史记录",
  text100004: "测试时间：",
  text100005: "主要角色",
  text100006: "备份角色",
  text100007: "个人数据",
  text100008: "团队数据",
  text100009: "分享",
  text100010: "下一步",
  text100011: "跳过说明，直接开始",
  text100012: "开始",
  text100013: "上一题",
  text100014: "下一题",
  text100015: "查看结果",
  text100016: "注意：现在的分配分数没到10分",
  text10001611: "注意：现在的分配分数超过10分",
  text100017: "测试报告",
  text100018: "整体数据",
  text100019: "根据你的分数分配，在8个角色中分别如下：",
  text100020: "自评主要角色",
  text100021:
    "得分最高的角色即是您在管理团队或项目团队中最适合扮演的团队角色。",
  text100022: "自评备份角色",
  text100023: "在团队不需要您的主要角色时，您可以扮演这些角色。",
  text100024: "自评低分角色",
  text100025:
    "最低的两个分数指出了被测者可能存在的弱点，但您并不非要在这些方面改变自己，而是应该找一个能在这些方面弥补自己缺点的同事。",
  text100026: "自评其他角色",
  text100027: "这是其他角色的说明，供您参考。",
  text100028: "查看「角色理论圆盘」",
  text100029: "角色理论圆盘",
  text100030: "返回测试主页",
  text100031: "查看题目记录",
  text100032: "自评",
  text100033: "他评均值",
  text100034: "主要",
  text100035: "备份",
  text100036: "邀请记录",
  text100037: "邀请他人为我测评",
  text100038: "分享",
  text100039: "正在创建团队",
  text100040: "选择一个结果加入",
  text100041: "测试时间",
  text100042: "主要角色",
  text100043: "备份角色",
  text100044: " 创建",
  text100045: "开始新测试",
  text100046: "返回测试首页",
  text100047: "查看题目记录",
  text100048: "整体数据",
  text100049: "根据你的分数分配，在8个角色中分别如下：",
  text100050: "成员",
  text100051: "主",
  text100052: "备",
  text100053: "邀请他人加入此团队",
  text100054: "*对方需有测试权限后才可加入",
  text100055: "团队角色测试",
  text100056: "自我测试量表",
  text100057: "他人测试量表",
  text100058:
    "本测试版权归贝尔宾所有，本测试仅将其测试方法通过程序实现自动评测",
  text100059:
    "要构建高效团队，需要团队成员角色互补、默契协作。八种关键角色: 塑造者、协调者、执行者、创新者、监督者、凝聚者、完成者、信息员。打造高战斗力团队需要每个队员都明晰各自所担当的主导角色及职责。",
  text100060:
    "此测试可帮助自我觉察和发现认知差距，广泛应用于团队的排兵布阵。单人测试结果只能帮助您从个人视角分析您在团队中的典型风格特征。若能邀请3-5位了解您的人共同完成测试，结果会更精准。",
  text100061: "提示：",
  text100062: "1、答案无对错好坏之分；",
  text100063: "2、该测试重点针对您当下在团队中的情况。",
  text100064: "测试问卷总共有7个部分",
  text100065:
    "每个部分包含10个问题。每个部分你都有10分可用于分配给这10个问题。请合理地将这10分分配到符合自己情况的选项上。在极端情况下，这10分可以分配到所有选项中，或全部分配给一个选项。",
  text100066:
    "然后把分数填到每一个选项后，可以直接点击加减，也可以填入一个数值。",
  text100067: "去购买",
  text100068: "测试报告",
  text100069: "返回",
  text100070: "加入",
  text100071: "团队",
  text100072: "等",
  text100073: "人",
  text100074: "匿名",
  text100075: "正在加入团队",
  text100076: "未完成",
  text100077: "已完成",
  text100078: "您有未保存的草稿，是否要使用？",
  text100079: "不使用",
  text100080: "使用草稿",
  text100081: "信息员",
  text100082: "凝聚者",
  text100083: "协调者",
  text100084: "塑造者",
  text100085: "完成者",
  text100086: "执行者",
  text100087: "监督者",
  text100088: "创新者",
  text100089: "请保存二维码并分享给你的团队成员",
  text100090: "或分享下面的链接给你的团队成员",
  text100091: "你可以在历史记录-团队数据中再次邀请他人",
  text100092: "请保存二维码并分享给你的朋友",
  text100093: "或分享下面的链接给你的团队朋友",
  text100094: "分享我的结果",
  text100095: "个人",
  text100096: "复制成功",
  text100097: "复制失败",
  text100098: "复制",
  text100099: "更符合TA",
  text100text100: "更符合我",
  text100101: "确认同分",
  text100102: "请先至个人数据确认角色",
  text100103:
    "若要团队有高效战斗力，每个队员都需要明晰各自所担当的主导角色及应有的行为。",
  text100104:
    "测测邀请人的主导角色与自我判断是否一致，帮TA了解自己在团队中的角色认知和感受。",
  text100105: "查看评测结果",
  text100106: "开始为TA评测",
  text100107: "自己不能给自己做他评!",
  text100108: "去团队角色首页",
  text100109: "根据你的分数分配，TA在8个角色中分别如下：",
  text100110: "TA的主要角色",
  text100111:
    "得分最高的角色即是TA在管理团队或项目团队中最适合扮演的团队角色。",
  text100112: "TA的备份角色",
  text100113: "在团队不需要您的主要角色时，TA可以扮演这些角色。",
  text100114: "TA的低分角色",
  text100115: "TA的其他角色",
  text100116: "我也要测试",
  text100117:
    "最低的两个分数指出了被测者可能存在的弱点，但TA并不非要在这些方面改变自己，而是应该找一个能在这些方面弥补自己缺点的同事。",
  text100118: "本题待分配分数",
  text100119: "你的主要角色中有同分角色，请参照描述选择一个更符合你的角色",
  text100120: "你的备份角色中有同分角色，请参照描述选择一个更符合你的角色",
  text100121: "帮",
  text100122: "评测TA在团队中的角色",
  text100123: "您需要完成7道题目",
  text100124: "加入团队",
  text100125: "根据团队的分数分配，",
  text100126: "团队评测报告",
  text100127: "在8个角色中分别如下：",
  text100128: "向您分享了Ta的团队角色测试结果",
  text100129:
    "在团队工作过程中，团队成员往往具有不同的合作特性。这些特性本身没有好坏的区分，但如果团队领导者能够预先了解每个人的合作特性，在考虑项目人员组成及任务分配时，就能更好的搭配人力资源，充分发挥每个人的特点。",
  text100130: "他评记录",
  text100131: "根据你的分数分配，",
  text100132: "最后一步",
  text100133: "TA的主要角色中有同分角色，请参照描述选择一个更符合TA的角色",
  text100134: "TA的备份角色中有同分角色，请参照描述选择一个更符合TA的角色",
  text100135: "待",
  text100136: "待定",
  text100137: "我是团队中天生的",
  text100138: "，快来测一测你是什么类型",
  text100139: "加入我的团队，测一测你扮演什么角色",
  text100140: "你认为我在团队中是什么角色？诚邀你为我评测",
  text100141: "暂无权限，请联系管理员",
  text100142: "复制成功，现在可以发送给你的朋友",
  text100143: "复制成功，现在可以邀请你的团队成员",
  text100144: "注意：现在的分配分数",
  text100145: "10分，请确认后继续操作",
  text100146: "超过了",
  text100147: "没达到",
  text100148: "已提交，不要重复提交",
  text100149: "注意：您第",
  text100150: "题未完成，请完成后继续",
  text100151: "抱歉，他评问卷提交名额已满，请勿再次提交",
  text100152: "加入成功",
  text100153: "登录失效，请重新登录",
  text100154: "请选择一个测试",
  text100155: "你已在该队伍中，请不要重复加入",
  text100156: "创建成功",
  text100157: "",
  text100158: "",
  text100159: "",
  text100160: "",
  text100161: "",
  text100162: "",
  text100163: "",
  text100164: "",
  text100165: "",
  text100166: "",
  text100167: "",
  text100168: "",
  text100169: "",
  text100170: "",
  200057: "个人登录",
  200001: "注册成功",
  200002: "发送失败",
  200003: "发送成功",
  200004: "重新发送",
  200005: "后重发",
  200006: "请输入手机号码",
  200007: "请输入全部信息",
  200008: "两次输入的密码不一致！",
  200009: "请再次输入密码",
  200010: "至少6个字符含数字和字母",
  200011: "请输入密码",
  200012: "请输入验证码",
  200013: "你已经注册，请登录",
  200015: "手机号码格式错误",
  200016: "账号不能空",
  200017: "发送验证码",
  200018: "再次输入密码",
  200019: "输入6~16密码",
  200020: "个人注册",
  200230: "记住密码",
  200039: "忘记密码",
  200056: "登录",
  200227: "切换语言",
  200054: "点此注册",
  200055: "没有账号",
  200197: "展开",
  200194: "登录成功!",
};
